<template>
    <div class="revenue-summary">
        <ul class="data-list">
            <li v-for="item in renderList"
                :class="itemClass(item.filed)"
                @click="changeChartDate(item)"
                :key="filed">
                <p class="data-name">{{item.name}}
                    <i class="remark-icon"
                       v-if="item.remark"
                       @click="showRemark(index,item)"></i>
                </p>
                <p class="time-data">
                    <span class="money"><span v-if="showUnit(item)">￥</span>{{item.time_data==.00?'0.00':item.time_data}}</span>
					<!-- valueKbit -->
                </p>
                <p class="month-data" v-if="item.month_data">
                    本月<span v-if="showUnit(item)">￥</span>{{item.month_data==.00?'0.00':item.month_data}}
					<!-- valueKbit -->
                </p>
            </li>
        </ul>
        <remark-window v-if="isShowRemark"
                       :remarkData="remarkData"
                       @closeWindow="isShowRemark=false"></remark-window>
    </div>
</template>

<script>
	export default {
		name:"revenue-summary",
		props:{
			data:{
				type:Object,
				default:()=>{}
			},
			timeType:{
				type:Number,
				default:1
			},
			chartParams:{
				type:Object,
				default:()=>{}
			},
		},
		components:{
			'remark-window':() => import('@/components/remark-window'),
		},
		data(){
			return {
				selectTime:null,
				currentMonth:null,
				renderList:[],
				dictionaryList:[
					{name:'消费金额',filed:'consume_account'},
					{name:'项目总个数',remark:"当前门店所有主项消费的总数量<br/>（数据取值参考门店后台软件项目明细中折算率的规则）",filed:'discount_dot'},
					{name:'主项总钟数',remark:"当前门店所有技师的主项上钟总数量<br/>（数据取值参考门店后台软件项目明细中核算钟数的规则）",filed:'main_project'},
					{name:'附项钟数',remark:"当前门店客人附项消费的总数量",filed:'attach_project'},
					{name:'房均消费',filed:'room_consume'},
					{name:'人均消费',filed:'person_consume'},
					{name:'结账房间数',filed:'room_count'},
					{name:'结账人数',filed:'person_count'},
				],
				//显示说明弹窗
				isShowRemark:false,
				remarkData:null,
			}
		},
		watch:{
			data:{
				deep:true,
				handler:'getRenderList',
			}
		},
		computed:{
			itemClass(){
				let {chartParams} = this
				return function(filed){
					return chartParams&&chartParams.filed === filed ? 'current' : ''
				}
			},
			showUnit(){
				return function(item){
					let {filed} = item
					if(filed == 'consume_account'
							|| filed == 'room_consume'
							|| filed == 'person_consume'){
						return true
					}
					return false
				}
			},
		},
		created(){
			this.getRenderList()
		},
		methods:{
			/**
			 * 生成渲染列表
			 */
			getRenderList(){
				let {data,timeType,dictionaryList} = this
				let selectTime = data.select_time_data
				let currentMonth = timeType < 3?this.data.current_month_data:null
				let renderList = []
				for(let dicItem of dictionaryList){
					let {name,filed,remark} = dicItem
					let time_data = selectTime[filed]
					let month_data = !!currentMonth ? currentMonth[filed] : null
					let renderItem = {
						name,
						time_data,
						month_data,
						filed,
						remark
					}
					renderList.push(renderItem)
				}
				this.renderList = renderList
				this.$emit('changeChartDate',renderList[0])
			},
			/**
			 * 切换走势图数据
			 */
			changeChartDate(item){
				this.currentType = item.filed
				this.$emit('changeChartDate',item)
			},
			/**
			 * 显示字段备注
			 */
			showRemark(index,item){
				this.remarkData = item
				this.isShowRemark = true
			}
		},
	}
</script>

<style lang="scss" type="text/scss" scoped>
    @import "~@/assets/style/public.scss";

    .revenue-summary{
        background:#FFFFFF;
        min-height: 580px;
    }

    .data-list{
        display:flex;
        flex-wrap:wrap;
    }

    .data-list li{
        padding:20px 20px 30px;
        border-bottom:1px solid #EEEEEE;
        width:33.33vw;
        text-align:left;

        .data-name{
            line-height:50px;
            font-size:30px;
            display:flex;
            align-items:center;
            white-space:nowrap;
        }
        .remark-icon{
            display:inline-block;
            width:24px;
            height:24px;
            background:url('~@/assets/images/remark-icon.png') no-repeat center/cover;
            border:10px solid transparent;
            margin:-2px 0 0;
        }
        .time-data{
            font-size:32px;
            font-weight:bold;
            line-height:40px;
            .money{
                font-size:30px;
            }
        }

        .month-data{
            font-size:24px;
            white-space:nowrap;
        }

        .data-list li p{
            max-width:100%;
            @extend %ellipsis;
        }

        &.current{
            p{color:$high-light-color;}

            p:last-child{
                position:relative;
                &:after{
                    content:'';
                    position:absolute;
                    bottom:-32px;
                    left:0;
                    right:0;
                    background:$high-light-color;
                    height:4px;
                }
            }
        }
    }

</style>
